import LuckyExcel from "luckyexcel";
import * as XSLX from 'xlsx';
// var canvasDatagrid = require('canvas-datagrid');
window.render_excel = (file) => {
        LuckyExcel.transformExcelToLuckyByUrl(file,'name1' ,function (exportJson, luckysheetfile){
            window.luckysheet.create({
                container: "luckysheet",
                data: exportJson.sheets,
                allowEdit: false,
                fullscreenmode: true,
                showinfobar: false,
                showtoolbar: false
            })
        })
}



window.render_sheetjs = (file) => {
    function update_sheet_range(ws) {
        var range = { s: { r: Infinity, c: Infinity }, e: { r: 0, c: 0 } };
        Object.keys(ws).filter(function (x) { return x.charAt(0) != "!"; }).map(XSLX.utils.decode_cell).forEach(function (x) {
            range.s.c = Math.min(range.s.c, x.c); range.s.r = Math.min(range.s.r, x.r);
            range.e.c = Math.max(range.e.c, x.c); range.e.r = Math.max(range.e.r, x.r);
        });
        ws['!ref'] = XSLX.utils.encode_range(range);
    }
    var oReq = new XMLHttpRequest();
    oReq.open('get', file, true)
    oReq.responseType = 'arraybuffer'
    oReq.onload = function(e){
        var data = new Uint8Array(oReq.response);
        var workbook = XSLX.read(data, {"type": "array"})
        var jsonData = {}
        workbook.SheetNames.forEach(function (sheetName){
            update_sheet_range(workbook.Sheets[sheetName]);
            var roa = XSLX.utils.sheet_to_json(workbook.Sheets[sheetName], { raw: false, header: 1 });
            if (roa.length > 0) jsonData[sheetName] = roa;
        })
        document.getElementById('luckysheet').style.width="100%"
        var grid = canvasDatagrid({parentNode: document.getElementById("luckysheet")});
        grid.style.width="100%"
        jsonData[workbook.SheetNames[0]].unshift(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",])

        // document.getElementById("luckysheet").appendChild = grid
        grid.data = jsonData[workbook.SheetNames[0]]
    }
    oReq.send(null)
}