// const { param } = require("jquery")

// let params 
// $(".main-link").on("click", function(){
//     params = {}
//     params[$(this).attr('data-category-type')] = $(this).attr('data-category-list')
//     get_parent_link($(this).parent().parent())
//     ty=""
//     li=""
//     for(const [key, value] of Object.entries(params)){
//         ty+=key+","
//         li+=value+","

//     }
//     window.location.href = "/documents?ty="+ty+"&li="+li
// })


// function get_parent_link(element){
//     parent = element.parent().parent()
//     link = $(parent.children()[0]).find('.menu-title')[0]
//     params[$(link).attr('data-category-type')] = $(link).attr('data-category-list')
//     top_level = $(link).attr('data-top')
//     console.log(typeof(top_level));
//     if(top_level=="false"){
//         console.log("not in top level");
//         get_parent_link($(parent[0]))
//     }
// }
$(".menu-link").on("click", function(){
    menu_link_nav(this)
})

$(".document_table_container").on("click",".menu-link", function(){
    menu_link_nav(this)
})

menu_link_nav = function(e){
    const ty = $(e).data('ty')
    const li = $(e).data('li')
    const my = $(e).data('my')
    const current_element = $(e)
    $.ajax({
        url: "/documents.js",
        method: "GET",
        data: { ty: ty, li: li, my: my },
        success: function (response) {
            // $(".tree_label a").removeClass('active')
            // $(".document_table_container").html(response)
        }
    })
    current_element.addClass("active")
}

$("document").ready(function(){
    $(".doc-show").on("click", function(e){
        console.log("clock");
    
        $.ajax({
            url: "/documents/"+$(this).data("id")+".js?so=1",
            method: "GET",
            success: function(response){
                console.log(response);
                $(".document_table_container").html(response)

            }
        })
    })
})
