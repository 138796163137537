// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
// require("turbolinks").start()
// import Turbolinks from 'turbolinks';
// window.Turbolinks = Turbolinks;
// application.js
import "trix"
import "@rails/actiontext"
import $ from "jquery";


// Bootstrap - The most popular framework uses as the foundation. Learn more: http://getbootstrap.com
window.bootstrap = require('bootstrap');
import './application.scss'


// Popper.js - Tooltip & Popover Positioning Engine used by Bootstrap. Learn more: https://popper.js.org
window.Popper = require('@popperjs/core');

// Select2 - Select2 is a jQuery based replacement for select boxes: https://select2.org/

// Select2 - Select2 is a jQuery based replacement for select boxes: https://select2.org/
require('select2/dist/js/select2.full.min.js');
// require('select2/dist/css/select2.css');
// window.select2 = require('select2')
window.moment = require("moment")
window.$ = require("jquery")
window.jquery = require("jquery")
var jQuery = require('jquery')
require("bootstrap-daterangepicker")

require.context('../images', true)

// import 'select2'
// Select2 Initialization
//

$.fn.select2.defaults.set("theme", "bootstrap5");
$.fn.select2.defaults.set("width", "100%");
$.fn.select2.defaults.set("selectionCssClass", ":all:");
// import 'select2/src/scss/core.scss';

// Keenthemes' plugins
window.KTUtil = require('../src/components/util.js');
window.KTCookie = require('../src/components/cookie.js');
window.KTDialer = require('../src/components/dialer.js');
window.KTDrawer = require('../src/components/drawer.js');
window.KTEventHandler = require('../src/components/event-handler.js');
window.KTFeedback = require('../src/components/feedback.js');
window.KTImage = require('../src/components/image-input.js');
window.KTMenu = require('../src/components/menu.js');
window.KTPasswordMeter = require('../src/components/password-meter.js');
window.KTPlace = require('../src/components/place.js');
window.KTScroll = require('../src/components/scroll.js');
window.KTScrolltop = require('../src/components/scrolltop.js');
// window.KTSearch = require('../components/search.js');

window.KTSticky = require('../src/components/sticky.js');
window.KTToggle = require('../src/components/toggle.js');
// Layout base js
window.KTApp = require('../src/layout/app.js');
window.KTLayoutAside = require('../src/layout/aside.js');
window.KTLayoutExplore = require('../src/layout/explore.js');

// require("@nathanvda/cocoon")
// window.KTLayoutSearch = require('../layout/search.js');
// window.KTLayoutToolbar = require('../src/layout/toolbar.js');

import '../src/bootstrap-datepicker.min';

window.toastr = require("../src/toastr")

window.FormFieldDependency = require("../src/form-field-dependency")

import "../src/menu";
import "../src/bootstrap-treeview.min";
import "../src/excel";
import 'datatables.net-bs4'
import '../src/form-field-dependency'

// require("form-field-dependency")

// window.formFieldDependency = require('');





// $("[data-dependency]").dependsOn()



// import pdfjsLib from "pdfjs-dist";
// console.log(pdfjsLib);

// pdfjs = require('pdfjs-dist')
// console.log(pdfjs);
Rails.start()
ActiveStorage.start()
// import "../src/pdf_view";